<template>
  <div class="apply-box">
    <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
      <el-radio-button :label="item.name" v-for="(item, index) in list" :key="index"></el-radio-button>
    </el-radio-group>
    <el-carousel height="340px" @change="changeTabs">
      <el-carousel-item v-for="item in list" :key="item.name">
        <div class="apply-details">
          <div class="apply-text">
            <h3>{{item.name}}</h3>
            <p class="descript">
              {{item.desc}}
            </p>
            <div class="see-details" @click="toPage(item.url)">
              了解详情
              <el-icon size="18" style="position: relative; top: 2px; margin-left: 5px;">
                <ArrowRight />
              </el-icon>
            </div>
          </div>
          <div class="apply-img">
            <img :src="item.img" alt="">
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script setup>
import { ref, reactive, computed, toRefs } from 'vue'
import { defineProps } from 'vue'
import { ArrowRight } from '@element-plus/icons'
const props = defineProps({
  list: {
    type: Array,
    default: () => ([])
  },
  showBtn: {
    type: Boolean,
    default: true
  }
})

const { list, showBtn } = toRefs(props)

const tabPosition = ref('供应链金融')

let nowTabs = computed(() => {
  const item = list.value.find(el => el.name === tabPosition.value)
  return item
})

function changeTabs(index) {
  console.log(list.value)
  tabPosition.value = list.value[index].name
}
</script>
<style lang='scss' scoped>
.apply-box {
  margin-top: 55px;
  padding: 50px 94px;
  width: 1200px;
  background-color: #fff;
}
.apply-details {
  display: flex;
  .apply-text {
    > h3 {
      padding: 32px 0;
      font-size: 30px;
      color: #202020;
    }
    .descript {
      text-align: left;
      font-size: 18px;
      line-height: 1.6em;
      color: #808080;
    }
    .see-details {
      display: flex;
      align-items: center;
      margin-top: 50px;
      font-size: 18px;
      color: #0766e1;
      cursor: pointer;
    }
  }
  .apply-img {
    flex-shrink: 0;
    margin-left: 93px;
    > img {
      width: 415px;
      height: 260px;
    }
  }
}

.technological-process {
  padding-bottom: 84px;
  background-color: #fff;
  .project-box {
    margin-top: 56px;
    > li {
      position: relative;
      box-sizing: border-box;
      padding: 48px 39px 63px;
      width: 225px;
      background-color: #f9fafd;
      cursor: pointer;
      .process-name {
        margin-bottom: 17px;
        font-size: 20px;
        color: #202020;
      }
      .project-img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 85px;
      }
    }
  }
  .project-details {
    > li {
      margin-bottom: 13px;
      font-size: 16px;
      color: #808080;
      &:hover {
        color: #0766e1;
      }
    }
  }
}
</style>