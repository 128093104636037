<template>
  <ul class="single-list">
    <li class="flex-between" v-for="(item, index) in list" :key="index">
      <img :src="item.img" alt="">
      <div class="product-introduce">
        <h3>{{item.name}}</h3>
        <p>{{item.desc}}</p>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { ref, reactive, computed, toRefs } from 'vue'
import { defineProps } from 'vue'
const props = defineProps({
  list: {
    type: Array,
    default: () => ([])
  }
})

const { list } = toRefs(props)

</script>
<style lang='scss' scoped>
@keyframes colorRight {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.single-list {
  border-radius: 6px;
  background-color: #fff;
  > li {
    position: relative;
    padding: 50px 60px 55px;
    border-bottom: 1px solid #f1f3f8;
    position: relative;
    transition: 0.7s linear;
    background-image: linear-gradient(to right, #fff 50%, #eee 50%);
    background-size: 200% 100%;
    &:hover {
      background-position: -100% 0;
      transition: 0.5s linear;
    }
    > img {
      margin-right: 64px;
      flex-shrink: 0;
      width: 72px;
    }
    .product-introduce {
      flex: 1;
      > h3 {
        margin-bottom: 20px;
        font-size: 28px;
        color: #202020;
      }
      > p {
        font-size: 16px;
        color: #808080;
      }
    }
  }
}
.btn {
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: #333333;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  position: relative;
  transition: 0.7s linear;
}
.btn:hover {
  color: #ffffff;
}
.btn:after {
  width: 0;
  cursor: pointer;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #2a68cc;
  transition: 0.7s linear;
}
.btn:hover:after {
  width: 150px;
}

.custom-list-style-color2 {
  padding-bottom: 30px;
  background: #436fdd;
  color: #fff;
  > h3 {
    color: #fff;
  }
}
</style>