<template>
  <BannerStyle1 :list="bannerList" :position="3"></BannerStyle1>
  <div class="usually-box">
    <section class="introduce top-box">
      <h3 class="title">产品概述</h3>
      <p class="descript">海马链引擎是知谦科技自主研发的区块链底层引擎，是针对企业区块链技术应用接入门槛高、业务适用性差、性能不足、操作复杂等问
        题，而设计开发的区块链框架和系统。它提供了一个面向企业应用场景的通用区块链框架系统，支持JAVA的SDK与合约代码，能够作为企
        业级区块链基础设施，为商业应用创新提供高效、灵活和安全的解决方案。</p>
    </section>
    <section class="superiority">
      <h3 class="title">核心优势</h3>
      <ul class="flex-between customize-list-style1">
        <li class="shadow-hover" v-for="(item, index) in superiorityList" :key="index">
          <img :src="item.img" alt="">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
          <div class="anim-bottom  introduce-box">
            <h3>{{item.name}}</h3>
            <p>{{item.desc}}</p>
          </div>
        </li>
      </ul>
    </section>

  </div>
  <section class="function-box usually-box">
    <h3 class="title">核心技术</h3>
    <SingleLine :list="funList"></SingleLine>
  </section>
  <section class="framework usually-box">
    <h3 class="title">产品架构</h3>
    <!-- <img style="width: 100%;" src="@/assets/product/engine/frame.png" alt=""> -->
    <el-image :src="src" :preview-src-list="srcList" />
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import SingleLine from '@/components/SingleLine'
import ResolveTemplate from '@/components/ResolveTemplate'
const bannerList = [
  {
    img: require("@/assets/product/engine/banner.png"),
    title: '海马链',
    desc: '高效、易用、自主研发的企业级区块链框架和系统',
    btnText: '了解产品',
    url: '/post/contact',
  }
]

const superiorityList = [
  {
    name: '高性能',
    img: require("@/assets/product/engine/goodness1.png"),
    desc: 'BaaS平台为企业提供一站式建链、用链、上链服务，提供便捷的管理工具和接入方式。',
  },
  {
    name: '跨链交易',
    img: require("@/assets/product/engine/goodness2.png"),
    desc: '通过身份链对企业证书透明管理，企业节点数据通过签名后完全可信，为数据交易和接口开放提供保障。',
  },
  {
    name: '简单易上手',
    img: require("@/assets/product/engine/goodness3.png"),
    desc: '无须深入学习区块链技术，即可快速搭建区块链系统，便捷地将业务系统和区块链系统对接，实现传统业务的区块链转型。',
  },
  {
    name: '快速应用',
    img: require("@/assets/product/engine/goodness4.png"),
    desc: 'BaaS平台为企业提供一站式建链、用链、上链服务，提供便捷的管理工具和接入方式。',
  },
  {
    name: '跨链交易',
    img: require("@/assets/product/engine/goodness5.png"),
    desc: '通过身份链对企业证书透明管理，企业节点数据通过签名后完全可信，为数据交易和接口开放提供保障。',
  },
  {
    name: '简单易上手',
    img: require("@/assets/product/engine/goodness6.png"),
    desc: '无须深入学习区块链技术，即可快速搭建区块链系统，便捷地将业务系统和区块链系统对接，实现传统业务的区块链转型。',
  },
]



const funList = [
  {
    name: '云网链边端技术',
    img: require('@/assets/product/engine/fun1.png'),
    desc: '将云、容器和安全技术、跨链通讯技术、链上应用技术、5G通信技术、边缘计算与隐私计算等技术融合的能力'
  },
  {
    name: '一体化智能合约',
    img: require('@/assets/product/engine/fun2.png'),
    desc: '智能合约和核心架构分离技术，具备合约生命周期管理、预执行等特色，合约语言：C++、Go等'
  },
  {
    name: '超级节点技术',
    img: require('@/assets/product/engine/fun3.png'),
    desc: '对外呈现为节点，对内呈现为分布式网络'
  },
  {
    name: '立体网络技术',
    img: require('@/assets/product/engine/fun4.png'),
    desc: '基于平行链、侧链、链内DAG并行技术的逻辑原理'
  },
  {
    name: '可插拔处理机制',
    img: require('@/assets/product/engine/fun5.png'),
    desc: '支持单链上多种共识机制无缝切换，当前支持DPOS、授权共识、PBFT，支持根据需求定制开发插件'
  },
]

const businessList = reactive([
  {
    id: 1,
    name: "供应链",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply1.png"),
  },
  {
    id: 2,
    name: "金融",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply2.png"),
  },
  {
    id: 3,
    name: "溯源",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply3.png"),
  },
  {
    id: 4,
    name: "政务民生",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply4.png"),
  },
  {
    id: 5,
    name: "要素交易",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply5.png"),
  },
  {
    id: 6,
    name: "电子商务",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply6.png"),
  },
  {
    id: 6,
    name: "其他定制化服务",
    title: "金融",
    desc: "供应链由众多参与主体构成，存在大量交互协作，信息被离散地保存在各自环节和系统中，缺乏透明度。区块链技术通过提供完整流畅的信息流、不可篡改的签名认证机制，可以实现多中心化的精准追溯和充分信任，天然地适用于供应链管理。",
    img: require("@/assets/home/apply7.png"),
  },
])


const src = require('@/assets/product/engine/frame.png');

const srcList = [
  require('@/assets/product/engine/frame.png')
]
</script>
<style lang='scss' scoped>
.title {
  padding: 94px 0 50px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.introduce {
  position: relative;
  z-index: 10;
  padding: 0 60px 100px;
  border-radius: 6px;
  background: #fff;
  > p {
    font-size: 18px;
    line-height: 1.7em;
    color: #808080;
  }
}
.function-box {
  padding-bottom: 80px;
  background-color: #436fdd;
  > h3 {
    color: #fff;
  }
}
.superiority {
  > ul {
    flex-wrap: wrap;
    > li {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      box-sizing: border-box;
      padding: 35px 30px 60px;
      width: 380px;
      height: 360px;
      background-color: #fff;
      > h3 {
        margin: 29px 0 21px;
        width: 100%;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
      }
      > img {
        height: 72px;
      }
      > p {
        font-size: 16px;
        line-height: 1.6em;
        color: #808080;
      }
    }
  }
}

.framework {
  margin-bottom: 50px;
  > ul {
    flex-wrap: wrap;
    > li {
      position: relative;
      margin-bottom: 15px;
      width: 390px;
      height: 390px;
      background-color: #fff;
      &:hover {
        .coverd {
          display: flex;
        }
      }
      .back-img {
        width: 100%;
        height: 100%;
      }
      .coverd {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        > img {
          height: 84px;
        }
        > h3,
        div {
          margin: 30px 0 27px;
          width: 100%;
          font-size: 28px;
          text-align: center;
        }
        > div {
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }
}
.place-box {
  padding-bottom: 70px;
  background: url("../../assets/home/resolve-back.png") no-repeat;
  background-size: cover;
  .small-title {
    text-align: center;
    color: #fff;
  }
  .descript {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>